<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="suppForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('suppForm')" />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="suppForm" :model="suppForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商编号" prop="supp_no">
            <!-- <el-input v-model="suppForm.supp_no" @input ="suppForm.supp_no = helper.keepEngNum1(suppForm.supp_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-input
              maxlength="10"
              @input="suppForm.supp_no = helper.pureEC(suppForm.supp_no).toUpperCase()"
              v-model="suppForm.supp_no"
              placeholder="请填写供应商编号"
              show-word-limit
              clearable
              :disabled="suppForm.status === 2"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商全称" prop="supp_name">
            <el-input v-model="suppForm.supp_name" maxlength="30" show-word-limit placeholder="请填写供应商全称" :disabled="suppForm.status === 2"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input v-model="suppForm.supp_abbr" maxlength="10" show-word-limit placeholder="请填写供应商简称" clearable :disabled="suppForm.status === 2">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商类型" prop="supp_type">
            <el-select v-model="suppForm.supp_type" placeholder="请选择供应商类型" size="mini" clearable show-word-limit>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系人姓名" prop="supp_contact">
            <el-input v-model="suppForm.supp_contact" maxlength="20" show-word-limit placeholder="请填写联系人" clearable> </el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="联系电话" prop="supp_phone">
            <el-input
              v-model="suppForm.supp_phone"
              @input="suppForm.supp_phone = helper.pureNumber(suppForm.supp_phone)"
              maxlength="50"
              show-word-limit
              clearable
              placeholder="请填写联系电话"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="主营类目" prop="supp_maincate">
            <!--            <el-select v-model="suppForm.supp_maincate" placeholder="请选择主营类目" size="mini" clearable show-word-limit>-->
            <!--              <el-option v-for="item in mainBusinessList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>-->
            <!--            </el-select>-->
            <el-input v-model="suppForm.supp_maincate" maxlength="30" show-word-limit placeholder="请填写主营类目"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="purc_stff_id">
            <el-select filterable v-model="suppForm.purc_stff_id" placeholder="请选择采购经办人" size="mini" clearable show-word-limit>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商地址" prop="supp_addr">
            <el-input
              v-model.trim="suppForm.supp_addr"
              type="textarea"
              autosize
              clearable
              maxlength="50"
              show-word-limit
              placeholder="请填写供应商地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="账套信息" prop="acct_id">
            <el-select v-model="suppForm.acct_id" @change="acctIdChange" placeholder="请选择账套" size="mini" clearable>
              <el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="专属客户" prop="cust_id">
            <el-select v-model="customSelected" placeholder="请专属客户" size="mini" filterable clearable show-word-limit>
              <el-option v-for="item in customList" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="托收工厂" prop="supp_factory">
            <el-radio v-model="suppForm.supp_factory" :label="1">是</el-radio>
            <el-radio v-model="suppForm.supp_factory" :label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="托收费率" prop="collection_rate">
            <el-input
              v-model.trim="suppForm.collection_rate"
              autosize
              clearable
              show-word-limit
              placeholder="请填写托收费率"
              @blur="suppForm.collection_rate = helper.calcPriceT(suppForm.collection_rate, 2, 10000)"
              @input="suppForm.collection_rate = helper.keepTNum2(suppForm.collection_rate)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商邮箱" prop="supp_email">
            <el-input v-model.trim="suppForm.supp_email" clearable show-word-limit placeholder="请填写"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商英文名称" prop="supp_ename">
            <el-input v-model.trim="suppForm.supp_ename" clearable show-word-limit placeholder="请填写"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="财务供应商简称" prop="supp_finance_abbr">
            <el-input v-model="suppForm.supp_finance_abbr" maxlength="10" show-word-limit placeholder="请填写财务供应商简称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商等级" prop="supp_grade">
            <el-select v-model="suppForm.supp_grade" clearable placeholder="请选择供应商等级" show-word-limit size="mini">
              <el-option label="A" value="A"></el-option>
              <el-option label="B+" value="B+"></el-option>
              <el-option label="B-" value="B-"></el-option>
              <el-option label="C" value="C"></el-option>
              <el-option label="D" value="D"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="抄送邮箱" prop="supp_copy_email_list">
            <el-select v-model="suppForm.supp_copy_email_list" allow-create default-first-option filterable multiple placeholder="请输入"></el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="suppForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { custAPI } from '@api/modules/cust';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'suppEditMain',
  components: {
    editHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        supp_no: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_name: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_type: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_abbr: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_addr: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_email: [{ required: true, trigger: 'blur', message: ' ' }],
        purc_stff_id: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      suppForm: {
        supp_no: null,
        supp_name: null,
        supp_type: null,
        supp_addr: null,
        supp_finance_abbr: null,
        supp_contact: null,
        supp_phone: null,
        supp_abbr: null,
        purc_stff_id: null,
        supp_maincate: null, //主营类目
        acct_id: '',
        acct_no: '',
        cust_id: ''
      },
      isShow: true,
      btn: {},
      stffForm: {},
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ],
      loadingFlag: false,
      stffList: [],
      mainBusinessList: [],
      acctList: [],
      acctListSelected: [],
      customList: [],
      customSelected: null
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getSuppInfo();
      this.getCompType();
      this.getStffUser();
      this.getMesAccountSetInfo();
      this.getCustomList();
    },
    getCustomList() {
      get(custAPI.getAllCustsV1, { status: 2 }).then(res => {
        if (res.data.code === 0) {
          this.customList = res.data.data.list;
        }
      });
    },
    getMesAccountSetInfo() {
      get(suppAPI.getMesAccountSetInfo)
        .then(({ data }) => {
          if (data.data.code === 0) {
            this.acctList = data.data.data.map(({ acct_no, acct_id }) => ({ acct_no, acct_id }));
          }
        })
        .catch(error => console.log(error));
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let tempObj = Object.assign({}, this.suppForm);
      tempObj.cust_id = this.customSelected;
      post(suppAPI.editSupp, tempObj)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.acctListSelected = [];
            this.suppForm.acct_id = null;
            this.suppForm.acct_no = null;
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '供应商编号重复'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getOptnByPermId, { perm_id: 10022 })
        .then(res => {
          if (res.data.code === 0) {
            this.mainBusinessList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 账套更改
    acctIdChange(value) {
      this.suppForm.acct_no = this.acctList.find(item => item.acct_id === value).acct_no;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取表单信息
    getSuppInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(suppAPI.getSuppById, { supp_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            // let splitAcctNo = res.data.data.form.acct_no.split(',');
            this.acctListSelected = [];
            this.customSelected = null;
            // res.data.data.form.acct_id.split(',').forEach((item,index) => {
            // 	if (item) this.acctListSelected.push(`${item},${splitAcctNo[index]}`)
            // });
            this.customSelected = res.data.data.form.cust_id;
            this.suppForm = res.data.data.form;
            // this.suppForm.acct_id = Number(this.suppForm.acct_id);
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.suppForm.stff_name;
            this.stffForm.dept_name = this.suppForm.dept_name;
            this.stffForm.dept_team_name = this.suppForm.dept_team_name;
            this.stffForm.user_id = this.suppForm.user_id;
            this.stffForm.dept_id = this.suppForm.dept_id;
            this.stffForm.stff_id = this.suppForm.stff_id;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            this.$message({ message: res.data.msg, type: 'error' });
          }
        })
        .catch(res => {
          this.$message({ message: res, type: 'error' });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
